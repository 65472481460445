import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"

import styled from "styled-components"

export default function instrukcja() {
  return (
    <Layout>
      <SEO title="Manifest" />
      <Wrapper>
        <List>
          <Number>1&#x205A;</Number>
          <p>
            Fizjologicznie zmysł smaku wygląda u nas podobnie, jednak percepcja
            smaku jest już kwestią nieskończenie subiektywną. Najsmaczniejszy
            rum to ten, który smakuje właśnie Tobie, właśnie teraz.
          </p>
          <Number>2&#x205A;</Number>
          <p>
            Nota degustacyjna wskazuje kierunek, wysuwa sugestię, suponuje. Smak
            trudno opisać... nie narażając się na śmieszność. Tu nie ma
            dogmatów, wszystko płynie. W razie wątpliwości - patrz punkt
            pierwszy.
          </p>
          <Number>3&#x205A;</Number>
          <p>
            Cena to koszt jaki jesteś skłonny ponieść, aby pozyskać dane dobro.
            Cena to często wyznacznik jakości, ale również instrument
            marketingowy. Mając powyższe na uwadze, skupię się na rumach poniżej
            50€ za butelkę.
          </p>
          <Number>4&#x205A;</Number>
          <p>
            Kolor nie jest sensownym kryterium podziału rumów. Na blogu stosuję
            ogólnie przyjętą klasyfikację ze względu na styl kolonialny oraz
            typologię wedle Luca Gargano naprzemiennie z{" "}
            <a
              href="https://www.thewhiskyexchange.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              thewhiskyexchange
            </a>
            .
          </p>
          <Number>5&#x205A;</Number>
          <p>
            Sekcja 'pretekst' to wstępne, luźne podejście do tematu. Sekcja
            'proces' skupia się na surowcu, metodach destylacji i procesie
            dojrzewania. 'Notka' to zdrobniale ujęta nota degustacyjna, taka z
            przymrużeniem oka, bo... patrz punkt drugi.
          </p>
          <Number>6&#x205A;</Number>
          <p>
            Jeśli schodzę na manowce, ba - szerzę fałszywe poglądy, to trzeba
            temu niezwłocznie i stanowczo przeciwdziałać. Jeśli w mych rumowych
            poszukiwaniach błądzę - napisz do mnie proszę{" "}
            <a href="mailto: rumexam@gmail.com">rumexam[at]gmail.com</a>{" "}
            <span role="img" aria-labelledby="please">
              🙏
            </span>{" "}
          </p>

          <Number>7&#x205A;</Number>
          <p>
            Korzystaj z umiarem. Alkohol to legalny narkotyk, a jego nadużywanie
            to poważny problem. Pomocy szukaj w oddolnych{" "}
            <a
              href="https://aa24.pl/pl"
              target="_blank"
              rel="noopener noreferrer"
            >
              grupach wsparcia
            </a>
            . Piłeś?{" "}
            <a
              href="http://www.pilesniejedz.pl/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Nie jedź!
            </a>
          </p>
        </List>

        <Copyright>
          <p>
            made with{" "}
            <span role="img" aria-labelledby="tumbler glass">
              🥃
            </span>{" "}
            by &#xa9; <a href="mailto: piotrend@gmail.com">piotrEnd</a>
          </p>
        </Copyright>
      </Wrapper>

      <Disclaimer>
        <h3>Polityka prywatności</h3>
        <p>
          Dokładam wszelkich starań, aby chronić prywatność odwiedzających
          stronę rumblog.pl. W razie dodatkowych pytań proszę o kontakt pod
          adresem <a href="mailto: rumexam@gmail.com">rumexam[at]gmail.com</a>
        </p>
        <h3>Zbieranie danych osobowych</h3>
        <p>
          Następujące rodzaje danych osobowych mogą być gromadzone,
          przechowywane i wykorzystywane:
        </p>
        <ul>
          <li>
            informacje o komputerze, w tym adres IP, lokalizacja geograficzna,
            typ i wersja przeglądarki oraz system operacyjny;
          </li>
          <li>
            informacje o Twoich wizytach i korzystaniu z tej witryny, w tym
            źródło odesłań, długość wizyty, wyświetlenia stron i ścieżki
            nawigacji w witrynie;
          </li>
          <li>
            informacje, które są generowane podczas korzystania z tej strony
            internetowej, w tym kiedy, jak często i w jakich okolicznościach z
            niej korzystasz;
          </li>
          <li>wszelkie inne dane osobowe, które zdecydujesz się przesłać.</li>
        </ul>
        <h3>Korzystanie z Twoich danych osobowych</h3>
        <p>
          Dane osobowe przesłane do nas za pośrednictwem naszej strony
          internetowej będą wykorzystywane do celów określonych w niniejszej
          polityce lub na odpowiednich stronach witryny. Możemy wykorzystywać
          Twoje dane osobowe do celu:
        </p>
        <ul>
          <li>administrowania tej strony internetowej i biznesem;</li>
          <li>
            umożliwienia korzystania z usług dostępnych na tej stronie
            internetowej;
          </li>
          <li>
            dostarczania stronom trzecim informacji statystycznych o
            użytkownikach (ale te osoby trzecie nie będą w stanie zidentyfikować
            żadnego konkretnego użytkownika na podstawie tych informacji);
          </li>
          <li>
            zajmowania się zapytaniami i skargami składanymi przez Ciebie lub
            dotyczącymi Ciebie w związku z tą witryną;
          </li>
          <li>
            zapewnienia bezpieczeństwa tej strony internetowej i zapobieganie
            oszustwom.
          </li>
        </ul>
        <p>
          Dane osobowe użytkownika mogą być ujawnione w zakresie, w jakim jest
          to wymagane przepisami prawa.
        </p>
        <h3>Międzynarodowe transfery danych</h3>
        <p>
          Informacje, które gromadzimy mogą być przechowywane i przetwarzane w
          każdym z krajów, w którym prowadzimy działalność i mogą być przesyłane
          pomiędzy tymi krajami w celu umożliwienia wykorzystania informacji
          zgodnie z niniejszymi „Zasadami ochrony prywatności”.
        </p>
        <h3>Bezpieczeństwo danych osobowych</h3>
        <p>
          Użytkownik przyjmuje do wiadomości, że transmisja informacji przez
          Internet jest potencjalnie niebezpieczna i bezpieczeństwo przesłanych
          w ten sposób danych nie jest gwarantowane.
        </p>
        <h3>Nowelizacje</h3>
        <p>
          Niniejsze zasady mogą być okresowo aktualizowane poprzez zamieszczenie
          w tej witrynie ich nowej wersji.
        </p>
        <h3>Strony internetowe osób trzecich</h3>
        <p>
          Ta strona internetowa zawiera hiperłącza do stron internetowych osób
          trzecich oraz szczegółowe informacje na ich temat. Nie mam kontroli i
          nie ponoszę odpowiedzialności za politykę prywatności i praktyki osób
          trzecich.
        </p>

        <h3>Ciasteczka</h3>
        <p>
          Używam Google Analytics na tej stronie internetowej, aby poprawić
          użyteczność i analizować korzystanie z tej strony internetowej.
          Większość przeglądarek pozwala odmówić przekazania plików cookie.
        </p>
      </Disclaimer>
    </Layout>
  )
}

const Wrapper = styled.div`
  /* max-width: 75%; */
  max-width: 90%;
  margin: 3rem auto 1rem;

  @media (min-width: 600px) {
    padding: 2rem 2rem;
  }

  @media (min-width: 800px) {
    width: 70%;
    padding: 2rem 3rem;
  }

  @media (min-width: 1200px) {
    width: 60%;
    padding: 2rem 4rem;
  }

  @media (min-width: 1300px) {
    width: 50%;
  }

  p {
    font-size: 1rem;

    @media (min-width: 500px) {
      font-size: 1.1rem;
    }

    @media (min-width: 700px) {
      font-size: 1.2rem;
    }
  }
`

const List = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr;

  p {
    margin: 1rem 0;
    line-height: 1.4;
    text-align: justify;

    @media (max-width: 550px) {
      font-size: 1rem;
      /* text-align: center; */
    }

    a {
      color: black;
    }
  }
`

const Number = styled.div`
  display: flex;

  font-family: "Spartan", sans-serif;
  font-size: 2rem;
  font-size: 1.5rem;

  align-items: center;
`
const Copyright = styled.footer`
  margin: 4rem auto;
  text-align: center;

  p {
    display: inline-block;
    font-size: 1rem;
  }

  a {
    text-decoration: none;
    color: black;
    transition: all 0.3s;

    :hover {
      color: #fafafa;
    }
  }
`

const Disclaimer = styled.div`
  margin: 1rem auto 0;

  background-color: #fcb712;
  color: #424242;

  /* max-width: 85%; */
  padding: 20px 50px;
  font-size: 1rem;
  max-width: 100%;

  h3 {
    margin: 2rem auto 1rem;
    text-align: center;
    font-family: "Spartan", sans-serif;
    font-weight: normal;
  }

  h3:first-of-type {
    font-size: 1.5rem;

    @media (min-width: 550px) {
      font-size: 2rem;
    }
  }

  p {
    text-align: justify;
  }

  p:last-child {
    margin-bottom: 0;
  }

  a {
    color: #424242;
    text-decoration: underline #424242;
  }

  ul {
    list-style-position: inside;
  }

  /* @media (min-width: 1100px) {
    width: 70%;
  }

  @media (min-width: 1200px) {
    width: 60%;
  }

  @media (min-width: 1300px) {
    width: 50%;
  } */
`
